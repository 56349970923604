<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold" v-if="custMdn">
                    전화번호 :
                    {{ custMdn | phoneNum }}
                </v-col>
                <v-col class="text-right mr-0 mt-0 pt-0">
                    <v-btn text @click="closePopup" color="white"><v-icon medium>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="text-right pt-5 pr-10 pb-0" v-if="groupDetail && groupDetail.cust_mdn">
            <v-icon color="grey" v-if="groupDetail && groupDetail.cust_label_type == '0'">mdi-account-circle</v-icon>
            <v-icon color="blue" v-if="groupDetail && groupDetail.cust_label_type == '1'">mdi-account-circle</v-icon>
            <v-icon color="yellow" v-if="groupDetail && groupDetail.cust_label_type == '2'">mdi-account-circle</v-icon>
            <v-icon color="red" v-if="groupDetail && groupDetail.cust_label_type == '3'">mdi-account-circle</v-icon>
            <v-icon color="black" v-if="groupDetail && groupDetail.cust_label_type == '4'">mdi-account-circle</v-icon>
            <span class="mr-5 ml-5">|</span>
            등록된 메모 수 : {{ memoList ? memoList.length : 0 | number }}
        </v-card-text>

        <template v-for="(item, idx) in memoList">
            <v-card-text class="pb-0 pl-7 pr-7 pt-3 mb-5" v-bind:key="'memo_' + idx">
                <v-row no-gutters>
                    <v-col class="pb-0 title" style="color:#999999">{{ item.writer_name }} | <span style="font-size:16px;">{{ item.reg_dt | dateMin }}</span></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="pt-2 pb-0">
                        <v-card outlined>
                            <v-card-text style="background-color:#f0f0f0; font-size:20px;">
                                {{ item.memo_text }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>

        <v-card-text>
            <v-row no-gutters>
                <v-col :class="memoList && memoList.length != 0 ? 'ma-3 mt-n2' : 'mt-5'">
                    <v-text-field
                        outlined
                        dense
                        hide-details
                        placeholder="상담메모 입력"
                        style="font-size: 20px;"
                        height="54"
                        v-model="memo"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters class="pr-5 pb-6 pt-0 ml-5">
                <v-col class="text-right">
                    <v-btn class="ma-2" width="130px" color="success" v-on:click="createMemo()"><span style="color:#eeeeee; font-size:1.4em;">메모등록</span></v-btn>
                    <v-btn class="ma-2" width="130px" color="grey" v-on:click="closePopup()"><span style="color:#eeeeee; font-size:1.4em;">닫기</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapState} from 'vuex'
import svcHistApi from '@/api/svc_hist'

export default {
    props: ['propCustSeqno', 'propCustMdn'],
    data: () => ({
        memo: '',
        custSeqno: 0,
        custMdn: ''
    }),
    computed: {
        ...mapState('svc_hist_cust_memo', {
            curSearchParam: state => state.memoSearchParam,
            memoList: state => state.memoList,
            groupDetail: state => state.groupDetail,
        }),
    },
    created() {
        this.custSeqno = this.propCustSeqno
        this.custMdn = this.propCustMdn
        this.getCustMemoList()
    },
    mounted() {
    },
    methods: {
        getCustMemoList() {
            if (this.custSeqno && this.custSeqno != 0) {
                this.$store.dispatch('svc_hist_cust_memo/getMemoList', {
                    searchParam: {
                        cust_seqno: parseInt(this.custSeqno)
                    }, scb: () => {
                    }, fcb: (err) => {
                        alert(err)
                    },
                    va: this
                })
            }
        },
        closePopup() {
            this.$emit('cancel')
        },
        createMemo() {
            /*if (this.$options.filters.isNull(this.groupDetail.cust_mdn)) {
                this.$store.dispatch('openAlert', {message: '고객 번호가 없습니다',})
            } else*/
            if (this.$options.filters.isNull(this.memo)) {
                this.$store.dispatch('openAlert', {message: '메모 내용을 입력해 주세요',})
            } else {
                svcHistApi.createCustMemo({
                    'cust_mdn': this.groupDetail && this.groupDetail.cust_mdn ? this.groupDetail.cust_mdn : this.custMdn,
                    'memo': this.memo
                }, (custSeqno, va) => {

                    if (this.custSeqno == 0) {
                        this.custSeqno = custSeqno
                    }

                    va.getCustMemoList()
                    va.memo = ''
                }, (err, va) => {
                    this.$store.dispatch('openAlert', {message: '메모 입력 중 오류가 발생하였습니다',})
                }, this)
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('svc_hist_cust_memo/setMemoList', [])
        this.$store.commit('svc_hist_cust_memo/setGroupDetail', {})
    }
}
</script>